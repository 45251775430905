function showToast(message, link, linkText) {
  let content = document.createElement('div');
  content.id = 'notice-toast';
  content.className = 'fixed flex bg-secondary-light dark:bg-white items-center w-max max-w-[95vw] p-4 space-x-4 text-gray-500 divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow md:top-[70px] bottom-10 right-5 md:bottom-auto md:left-auto dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 z-[9999] slide-in';
  content.setAttribute('role', 'alert');

  let messageDiv = document.createElement('div');
  messageDiv.className = 'md:text-base text-sm text-white dark:text-primary-text-light font-normal';
  messageDiv.textContent = message;
  content.appendChild(messageDiv);

  if (link && linkText) {
    let linkDiv = document.createElement('div');
    linkDiv.className = 'w-max pl-2 !ml-[5px]';
    
    let anchor = document.createElement('a');
    anchor.className = 'flex items-center md:text-base text-sm text-primary font-bold';
    anchor.href = link;
    anchor.textContent = linkText;
    
    linkDiv.appendChild(anchor);
    content.appendChild(linkDiv);
  }

  document.querySelector("body").appendChild(content);

  setTimeout(() => {
    content.remove();
  }, 5000);
}

window.showToast = showToast;

export { showToast };